import React, { useContext, useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';

import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';

const ContactForm = () => {
  const [formSent, setFormSent] = useState(false);
  const [formInput, setFormData] = useState({
    'form-name': 'contact2',
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const onChange = ({ target }) =>
    setFormData((f) => ({
      ...f,
      [target.getAttribute('type')]: target.value,
    }));

  const onSubmit = () => {
    function encode(data) {
      return Object.keys(data)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
        .join('&');
    }

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode(formInput),
    })
      .then(() => {
        setFormSent(true);
      })
      .catch((error) => alert(error));
  };

  const { name, email, subject, message } = formInput;

  return (
    <div>
      <Flip left opposite when={formSent}>
        <p className="contact-wrapper__text thank-you">
          {"Thank You! We'll get back to you ASAP :)"}
        </p>
      </Flip>
      <Flip left when={!formSent}>
        <Form className="contact-form" name="contact2" method="POST" data-netlify="true">
          <input type="hidden" name="form-name" value="contact2" />
          <Row xs={1} md={2}>
            <Col>
              <Form.Control
                size="lg"
                type="name"
                placeholder="Name"
                onChange={onChange}
                value={name}
              />
            </Col>
            <Col>
              <Form.Control
                size="lg"
                type="email"
                placeholder="Enter email"
                onChange={onChange}
                value={email}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Control
                size="lg"
                type="subject"
                placeholder="Subject"
                onChange={onChange}
                value={subject}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Control
                as="textarea"
                size="lg"
                type="message"
                placeholder="Message"
                onChange={onChange}
                value={message}
              />
            </Col>
          </Row>
          <button
            type="button"
            rel="noopener noreferrer"
            className="cta-btn cta-btn--resume contact-button"
            onClick={onSubmit}
          >
            {'Send Message!'}
          </button>
        </Form>
      </Flip>
    </div>
  );
};

const Contact = () => {
  const { contact } = useContext(PortfolioContext);
  const { cta, btn, email } = contact;

  return (
    <section id="contact">
      <Container>
        <Title title="Contact" />
        <Fade bottom duration={1000} delay={800} distance="30px">
          <div className="contact-wrapper">
            <p className="contact-wrapper__text">
              {cta || 'Would you like to work with us? Awesome!'}
            </p>
            <ContactForm />
          </div>
        </Fade>
      </Container>
    </section>
  );
};

export default Contact;
