import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
// import { useInView } from 'react-hook-inview'
import { Container, Row, Col } from 'react-bootstrap';
import Title from '../Title/Title';
import AboutImg from '../Image/AboutImg';
import PortfolioContext from '../../context/context';

const About = () => {
  const { about } = useContext(PortfolioContext);
  const { img, paragraphOne, paragraphTwo, paragraphThree, resume } = about;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="about" >

      <Container>
        <Title title="About Us" />
        <Row className="about-wrapper">
          <Col md={6} sm={12}>
            <Fade bottom duration={1000} delay={600} distance="30px">
              <div className="about-wrapper__image">
                <AboutImg alt="profile picture" filename={img} />
              </div>
            </Fade>
          </Col>
          <Col md={6} sm={12}>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <div className="about-wrapper__info">
                <p className="about-wrapper__info-text">
                  {paragraphOne ||
                    `AppKonekt is a high-end marketing studio that specializes in app and services monetization and solutions.`}
                </p>
                <p className="about-wrapper__info-text">
                  {paragraphTwo || `We are a 100% digital media agency with AdTech experts including creatives, marketers, developers and managers.`}
                </p>
                <p className="about-wrapper__info-text">
                  {paragraphThree || `We believe in people & technology and combine them to generate great results for our clients.`}
                </p>
                <p className="about-wrapper__info-text">
                  {paragraphThree || ``}
                </p>
              </div>
            </Fade>
          </Col>
        </Row>
        <h2 className='about-mission'>Our mission is to distribute better apps for users and better compensation for creators.</h2>

      </Container>


    </section>
  );
};

export default About;
