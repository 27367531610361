import React, { useContext } from 'react';
import { Container, Navbar, Button } from 'react-bootstrap';
import { Link } from 'react-scroll';
import PortfolioContext from '../../context/context';
import LogoImg from '../Image/LogoImg';

const Header = () => {
  const { header } = useContext(PortfolioContext);
  // const { networks } = header;
  // const { isEnabled } = githubButtons;

  return (
    <header className="header">
      <Container>
        <Navbar fixed="top" expand="lg">
          <Navbar.Brand href="/" className="logo">
            <LogoImg filename={'logo.png'} />
            <div>

            AppKonekt
            </div>
          </Navbar.Brand>
          <Navbar.Collapse className="justify-content-end">
            {/* <Navbar.Text> */}
            <p>
              <span className="cta-btn cta-btn--header">
                <Link to="contact" smooth duration={1000}>
                  Let's Talk
                </Link>
              </span>
            </p>

            {/* </Navbar.Text> */}
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </header>
  );
};

export default Header;
